<template>
	<div>
		<form v-on:submit.prevent="save">
			<div class="row mb-3">
				<div class="col-12 col-md-6 col-lg-8">
					<h1><em class="far fa-user"></em> My Account</h1>
				</div>
				<div class="col-12 col-md-6 col-lg-4 text-right">
					<button type="button" class="btn btn-secondary ml-1" v-on:click="$router.push('/')"><em class="fas fa-times" aria-hidden="true"></em> Close</button>
					<button type="submit" class="btn btn-primary ml-1"><em class="fas fa-save" aria-hidden="true"></em> Save</button>
				</div>
			</div>

			<div class="row">
				<div class="col-12 col-lg-6">
					<div class="card mb-2">
						<div class="card-header"><b>User Information</b></div>

						<div class="card-body">
							<div class="form-row mb-2">
								<div class="col-12 col-md-6 col-lg-4 col-xl-3">
									<label for="fname" class="required">First Name</label>
								</div>
								<div class="col-12 col-md-6 col-lg-8 col-xl-9">
									<input type="text" id="fname" v-model="user.fname" class="form-control" required />
								</div>
							</div>
							<div class="form-row mb-2">
								<div class="col-12 col-md-6 col-lg-4 col-xl-3">
									<label for="lname" class="required">Last Name</label>
								</div>
								<div class="col-12 col-md-6 col-lg-8 col-xl-9">
									<input type="text" id="lname" v-model="user.lname" class="form-control" required />
								</div>
							</div>
							<div class="form-row mb-2">
								<div class="col-12 col-md-6 col-lg-4 col-xl-3">
									<label for="email" class="required">Email</label>
								</div>
								<div class="col-12 col-md-6 col-lg-8 col-xl-9">
									<input type="email" id="email" v-model="user.email" class="form-control" required />
								</div>
							</div>
							<div class="form-row mb-2">
								<div class="col-12 col-md-6 col-lg-4 col-xl-3">
									<label for="username">Username</label>
								</div>
								<div class="col-12 col-md-6 col-lg-8 col-xl-9">
									{{user.username}}
								</div>
							</div>
							<div class="form-row mb-2">
								<div class="col-12 col-md-6 col-lg-4 col-xl-3">
									<label for="password">Password</label>
								</div>
								<div class="col-12 col-md-6 col-lg-8 col-xl-9">
									<input type="password" id="password" v-model="user.password" class="form-control" placeholder="Leave blank unless changing it" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	export default {
		name: 'Account',
		props: ["session_id", "logged_in"],
		data() {
			return {
				user: {user_id: null}
			}
		},
		mounted: function()
		{
			if(!this.logged_in)
			{
				return;
			}

			this.load();
		},
		methods:
		{
			load: function()
			{
				var vm = this;

				vm.startLoading();

				this.CORS('GET', "/users", null,
					function(data)
					{
						vm.user = data;
						vm.stopLoading();
					}, function()
					{
						vm.stopLoading();
						vm.showError("Error", "Error loading user.", true, null);
					});
			},
			save: function()
			{
				var vm = this;
				var url = "";
				var method = "";
				vm.startLoading();

				if(Number(vm.user.user_id) > 0)
				{
					url = "/users/" + vm.user.user_id;
					method = "PUT";
				}
				else
				{
					url = "/users";
					method = "POST";
				}

				this.CORS(method, url, JSON.stringify(this.user),
					function(data)
					{
						data.password = "";
						vm.user = data;
						vm.stopLoading();
						vm.$router.push("/");
					}, function(response)
					{
						vm.stopLoading();
						vm.showError("Error", "Error saving user: " + response.responseText, true, null);
					});
			}
		}
	}
</script>
